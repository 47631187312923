@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Open Sans', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Montserrat', sans-serif;
}

.accent {
  font-family: 'Lora', serif;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
@supports (-ms-ime-align: auto) {
  /* Edge-specific styles */
  .icon-class {
    font-size: 1rem; /* Adjust as needed */
  }
}
:root {
  font-family: 'Lora', serif;
  line-height: 1.5;
  font-weight: 400;

  color-scheme: light dark;
  color: #f4f3ee;
  background-color: #242424;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  scrollbar-width: thin;
}

a {
  font-weight: 500;
  color: inherit;
  text-decoration: inherit;
}

a:hover {
  color: #8c8c8c;
}

body {
  margin: 0;
  display: flex;
  place-items: center;
  min-width: 320px;
  min-height: 100vh;
}


button {
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 0.6em 1.2em;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  background-color: #1a1a1a;
  cursor: pointer;
  transition: border-color 0.25s;
}

button:hover {
  border-color: #646cff;
}

button:focus,
button:focus-visible {
  outline: 4px auto -webkit-focus-ring-color;
}